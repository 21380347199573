import React, { useEffect, useState } from "react";
import { paymentModule } from "@constants";
import "./payments.scss";

const TITLE = "Онлайн пополнение";

const Payment = () => {
	const [error, setError] = useState("");

	function loadScript(url, callback) {
		let script = document.createElement("script");
		script.type = "text/javascript";
		script.noModule = false;

		if (script.readyState) {
			//IE
			script.onreadystatechange = function() {
				if (
					script.readyState === "loaded" ||
					script.readyState === "complete"
				) {
					script.onreadystatechange = null;
					callback();
				}
			};
		} else {
			//Others
			script.onload = function() {
				callback();
			};
			script.onerror = function() {
				setError("Сервис временно недоступен");
			};
		}
		script.src = url;
		document.getElementsByTagName("body")[0].appendChild(script);
	}

	const mount = () => {
		if (
			window &&
			window["sc-online-payment"] &&
			window["sc-online-payment"].mount &&
			typeof window["sc-online-payment"].mount === "function"
		) {
			try {
				window["sc-online-payment"].mount({
					el: "payment",
					title: TITLE,
					share: true,
					isWide: true
				});
			} catch (e) {
				setError("Сервис временно недоступен");
			}
		}
	};

	useEffect(() => {
		if (!window["sc-online-payment"]) {
			loadScript(paymentModule, mount);
		} else {
			mount();
		}
	}, []);

	return (
		<div id="payment">
			<section className="full-width payment-loading">
				{!error ? (
					<>
						<div className="grid-container">
							<div className="row">
								<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-8">
									<h1>{TITLE}</h1>
									<section className="preloader-paragraph">
										<div />
										<div />
									</section>
									<div className="preloader-input" />
								</div>
							</div>
						</div>
						<div className="preloader-block" />
					</>
				) : (
					<div className="grid-container">
						<div className="row">
							<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-8">
								<div className="error">
									<p>{error}</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</section>
		</div>
	);
};

export default Payment;
